import { Button } from "@o4c/plugin-components";
import { FunctionComponent, useEffect, useState } from "react";
import { IMember } from "../../interfaces/member.interface";
import { ISchedule } from "../../interfaces/schedule.interface";
import * as Api from "../../services/Api";
import { useAppSelector } from "../../store/hooks";
import ManagerCard from "./ManagerCard";
import { sortByGroups } from "../utils";
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect';

export interface IInlineProps { }

const Inline: FunctionComponent<IInlineProps> = () => {

	const {
		config: { token, magazineId, universalLink, webUniversalLink },
		member: { member },
	} = useAppSelector((state) => state);

	const { t } = useTranslation();

	const [allSchedules, setAllSchedules] = useState<
		{ member: IMember; schedules: ISchedule[] }[]
	>([]);

	async function getAllSchedules(signal: AbortSignal) {
		try {
			const schedules = await Api.getTodaysScheduleByTeam(
				token,
				magazineId,
				member.teams,
				signal
			);
			schedules.sort((a, b) => sortByGroups(a.member, b.member, member));
			setAllSchedules(schedules);
		} catch (error) {
			// TODO: Global handle error with redux
			console.error(error);
		}
	}

	const handleRedirect = () => {
		if (!isMobile && window.parent) {
			window.parent.postMessage({ type: "redirect", to: webUniversalLink }, "*");
			return;
		}
		const link = document.createElement("a");
		link.href = universalLink;
		link.dispatchEvent(new MouseEvent("click"));
	}

	const handleEditRedirect = async (isEdit: boolean, scheduleOwnerUid: string) => {
		await Api.saveState(+magazineId, isEdit, scheduleOwnerUid, member.uid);
		handleRedirect();
	};

	useEffect(() => {
		if (!member.teams || !member.teams.length) return;
		const controller = new AbortController();
		getAllSchedules(controller.signal);
		return () => {
			controller.abort();
		};
	}, [member]);

	return (
		<div className="home-container">
			<div className="title-container">
				<h3 className="text-primary home-title">{t("where")}</h3>
				{member.manager && (
					<Button
						className="btn-grey"
						display="inline"
						size="small"
						type="outline"
						onClick={() => handleEditRedirect(true, member.uid)}>
						{t("edit my schedule")}
					</Button>
				)}
			</div>
			<div className="card-container-wrapper">
				<div className="card-container">
					{(allSchedules &&
						allSchedules.length &&
						allSchedules.map(({ member, schedules: mSchedules }, i) => (
							<ManagerCard
								key={i}
								member={member}
								mSchedules={mSchedules}
								handleClick={handleEditRedirect}
							/>
						))) || <p className="text-primary">{t("no schedule found")}</p>}
					<div className="last-card"></div>
				</div>
			</div>
			<Button className="primary btn-custom-primary" onClick={handleRedirect}>{t("show all")}</Button>
		</div>
	);
};

export default Inline;
